import 'bootstrap'
import * as $ from 'jquery'
import './../styles/styles.scss'

function runModule(module) {
  let page = module.default
  $(document).ready(function() {
    page()
  })
}

import(/* webpackChunkName: "navbar" */ './navbar').then(runModule)

switch (jsPage) {
  case 'login':
    import(/* webpackChunkName: "login" */ './login').then(runModule)
    break
  case 'password-reset':
    import(/* webpackChunkName: "password-reset" */ './password-reset').then(runModule)
    break
  case 'vehicleView':
    import(/* webpackChunkName: "vehicle" */ './vehicle').then(runModule)
    break
  case 'info':
    import(/* webpackChunkName: "info" */ './info').then(runModule)
    break
  case 'account':
    import(/* webpackChunkName: "account" */ './account').then(runModule)
    break
  case 'support':
    import(/* webpackChunkName: "support" */ './support').then(runModule)
    break
  case 'refer':
    import(/* webpackChunkName: "refer" */ './refer').then(runModule)
    break
  case 'tracker':
    import(/* webpackChunkName: "tracker" */ './tracker').then(runModule)
    break
  case 'renewals':
    import(/* webpackChunkName: "renewals" */ './renewals').then(runModule)
    break
  default:
    console.log('no page js loaded')
    break
}

$(document).ready(function() {
  $('#vehicleChange').change(() => {
    window.location.href = window.location.origin + '/vehicle/' + $('#vehicleChange').val()
  })
})
